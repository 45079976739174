<template>
	<div class="page mt-3">
		<collapse v-model="activeNames" accordion :border="false" @change="handleCollapseChange">
			<van-radio-group v-model="checkType" @change="handleCheckType">
				<collapse-item v-for="i in PermissionType" :name="i.id"
				               :class="{'no-content': i.id === 0}" @click="checkType = i.id">
					<template #title>
						<p class="color-black b fs-16">{{ i.title }}</p>
						<div class="flex h-between">
							<p class="fs-12 color-gray">{{ i.label }}</p>
							<transition name="van-fade">
								<p v-show="i.id !== 0  && activeNames === i.id " class="refresh-tag color-blue fs-12"
								   @click.stop="handleSyncTags">
									<van-icon name="replay" size=".16rem"/>
									刷新标签
								</p>
							</transition>
						</div>
					</template>
					<template #icon>
						<!--						<van-radio :name="i.id" class="type-check" icon-size=".16rem" @click="handleCheckType(i)"-->
						<van-radio :name="i.id" class="type-check" icon-size=".16rem"
						           :checked-color="i.id === 1 ? '#FF445E' : '#129729'"></van-radio>
					</template>
					<!--无限制不展示-->
					<tag-group v-model="checkTag" v-if="i.id !== 0" :list="tagList"
					           :checkColor="i.id === 1 ? 'warning' : 'green'"/>
					<!--所有人可见时将右边icon置空-->
					<template #right-icon v-if="i.id === 0"/>
				</collapse-item>
			</van-radio-group>
		</collapse>
		<button :disabled="buttonLoading" class="button confirm-save" @click="handleSave">保存</button>
	</div>
</template>

<script>
export default {
	name: 'friendCirclePermission'
}
</script>
<script setup>
import { Collapse, CollapseItem, Notify, Toast } from 'vant'
import { markRaw, ref, computed, onMounted, warn } from 'vue'
import TagGroup from './TheTagGroup'
import {
	ApiGetFriendCircleSend,
	ApiGetWxTagList,
	ApiSetFriendCircleSend,
	ApiSyncRobotTags
} from '../../api/FriendCircle'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const robotInfo = computed(() => {
	return store.getters.robotInfo
})
const buttonLoading = ref(false)
const PermissionType = [
	{
		id: 0,
		title: '公开',
		label: '所有人可见'
	},
	{
		id: 2,
		title: '部分可见',
		label: '选中的微信标签好友可见'
	},
	{
		id: 1,
		title: '不给谁看',
		label: '选中的微信标签好友不可见'
	}
]
// 收缩框激活id
const activeNames = ref()
// 选中类型
let checkType = ref()
const checkTag = ref(new Set())
const tagList = ref([])

onMounted(() => {
	getWxTagList()
	getFriendCirclePermissions()
})
// 获取朋友圈权限
const getFriendCirclePermissions = async () => {
	try {
		const res = await ApiGetFriendCircleSend(robotInfo.value.robotWxId)
		// 可见类型 0：默认无限制 1：指定不可见 2：指定可见
		activeNames.value = res.privateType
		checkType.value = res.privateType
		res.tagList.forEach(i => {
			checkTag.value.add(i.tagId)
		})
	} catch (e) {
		console.warn(e)
	}
}
// 获取微信标签
const getWxTagList = async () => {
	try {
		const res = await ApiGetWxTagList(robotInfo.value.robotWxId)
		tagList.value = res ? res : []
	} catch (e) {
		console.warn(e)
	}
}
// 同步微信标签
const handleSyncTags = async () => {
	try {
		await ApiSyncRobotTags(robotInfo.value.robotWxId)
		Toast.loading({
			duration: 3000,
			forbidClick: true,
			message: '标签同步中，请稍后...'
		})
		setTimeout(() => {
			Notify({
				type: 'success',
				message: '同步成功'
			})
			getWxTagList()
		}, 3000)

	} catch (e) {
		console.warn(e)
	}
}
// 收缩框改变时触发
const handleCollapseChange = (val) => {
	// 当前展开并且与选择框值不一样就清空 防止重复点击
	if (val && val !== checkType.value) {
		checkTag.value.clear()
	}
}
// 通过点击选择器触发
const handleCheckType = (val) => {
	// 当前选择框与展开框不一致时清空 防止重复点击
	if (val !== activeNames.value) {
		checkTag.value.clear()
	}
}
const handleSave = async () => {
	const tagIds = [...checkTag.value].join(',')
	if (activeNames.value !== 0 && tagIds.length === 0) {
		Notify({
			type: 'warning',
			message: '请先选择标签哦！'
		})
		return
	}
	buttonLoading.value = true
	try {
		const data = {
			robotWxId: robotInfo.value.robotWxId,
			privateType: activeNames.value,
			tagIds: tagIds
		}
		await ApiSetFriendCircleSend({ ...data })
		Notify({
			type: 'success',
			message: '保存成功'
		})
		await router.push('/index')
		buttonLoading.value = false
	} catch (e) {
		buttonLoading.value = false
		console.log(e)
	}
}
</script>
<style lang="scss" scoped>
.page {
	background-color: #fff;
	padding-left: 15px;

	:deep(.van-collapse) {
		.van-cell {
			padding-left: 25px;
		}

		.van-cell--clickable {
			&:active {
				background-color: unset;
			}
		}

		.van-collapse-item--border {
			&:after {
				left: 25px;
			}

			.van-cell {
				&:after {
					left: 25px;
				}
			}
		}

		.van-collapse-item__content {
			padding-left: 25px;
		}
	}
}

.no-content {
	// 关闭公开的展开效果
	:deep(.van-collapse-item__wrapper) {
		max-height: 0;
	}
}

.type-check {
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0.26rem;
	padding-right: 10px;
}

.refresh-tag {
	margin-right: -20px;
}

.confirm-save {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 120px;
	height: 32px;
	background: #FF445E;
	color: rgba(255, 255, 255, .7);
	box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
	border-radius: 16px 16px 16px 16px;
}

</style>
